const config = {
  ENVIRONMENT_NAME: 'testing',
  forwoodId: {
    URL: 'https://id.testing.newmont.forwoodsafety.com',
    USER_TOKEN_URL: 'https://0zpyebz5x8.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://4kzj316pja.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'teams-container-1.10.7',
    HOSTNAME: 'https://teams.testing.newmont.forwoodsafety.com',
    TEAMS_MICROFRONTEND_URL: 'https://teams-service.testing.newmont.forwoodsafety.com',
    TARGETS_MICROFRONTEND_URL: 'https://targets.testing.newmont.forwoodsafety.com',
    TEAMS_ACCESS_ROLE: 'TeamsAccess',
    TEAM_TARGETS_ACCESS_ROLE: 'TeamTargetsAccess',
  },
  configurationService: {
    URL: 'https://config.testing.newmont.forwoodsafety.com',
    WEBSOCKET: 'wss://boxrmt5ywk.execute-api.us-west-2.amazonaws.com/testing'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
